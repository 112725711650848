<template>
  <div class="finance increment-wrap">
    <div class="line" />
    <h2>金融服务</h2>
    <div class="img">
      <!-- <div class="title">金融服务</div> -->
      <p class="text">
        山水物联网络货运平台依托核心企业确权，以真实货运业务场景为基础、以运费定向支付为管控手段，最大限度控制贷款风险，极大降低风控管理成本。为金融机构的供应链金融产品投放打通安全通道，进一步降低了物流企业的融资成本。
      </p>
      <div style=" text-align:center;"><img src="./../../assets/img/8.jpg" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";

</style>
